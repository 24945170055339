import { getImportantAnnouncements } from '../../api/importantAnnouncements'

export default {
  namespaced: true,
  state: {
    announcements: []
  },
  mutations: {
    SET_ANNOUNCEMENTS (state, payload) {
      // console.log('setting dummy announcements ', payload)
      state.announcements = payload
    },
    ADD_NEW_ANNOUNCEMENT (state, payload) {
      state.announcements.push(payload)
    },
    UPDATE_ANNOUNCEMENT (state, payload) {
      const idx = state.announcements.findIndex(elem => elem.id === payload.id)
      state.announcements[idx] = payload
    },
    DELETE_ANNOUNCEMENT (state, payload) {
      state.announcements = state.announcements.filter(elem => elem.id !== payload.id)
    }
  },
  getters: {
    allAnnouncements: state => state.announcements,
    announcementById: (state, getters) => id => getters.allAnnouncements.find(elem => elem.id === id)
  },
  actions: {
    loadAnnouncements ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        getImportantAnnouncements(payload)
          .then((r) => {
            // console.log('raw data')
            // console.log(r.data)
            const formattedAnnouncements = r.data.map(announcement => {
              return {
                id: announcement.id,
                Description: announcement.description,
                activatedAt: announcement.activatedAt,
                expiresAt: announcement.expiresAt,
                active: announcement.status === 'ACTIVE'
              }
            })

            commit('SET_ANNOUNCEMENTS', formattedAnnouncements)
            resolve(r)
          }).catch((error) => reject(error))
      })
    },

    addNewAnnouncement ({ commit }, payload) {
      commit('ADD_NEW_ANNOUNCEMENT', payload)
    },

    update_Announcement ({ commit }, payload) {
      commit('UPDATE_ANNOUNCEMENT', payload)
    },

    delete_Announcement ({ commit }, payload) {
      commit('DELETE_ANNOUNCEMENT', payload)
    }
  }
}
