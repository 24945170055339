<template>
  <SuiModal v-model="dialog">
    <SuiModalContent style="height: 530px; display: flex; flex-direction:column">
      <a>
        <i
          @click="close()"
          style="text-decoration:none !important; cursor: pointer;font-size: 25px;
          position: sticky; left: 105%; top: 10px; margin-right: -5px; margin-top: -5px;"
          class="times icon general-blue-color">
        </i>
      </a>
      <SuiModalDescription style="width: 100%; padding-right:15px; padding-left:15px; display: flex; flex-direction: column;">
        <h1 v-if="isNewBulletin" style="margin-top: -15px">{{  $t('board.create_announcement') }}</h1>
        <h1 v-else style="margin-top: -15px">{{edit_data.values.title}}</h1>

        <form
          class="ui form fields"
          :class="{'blue medium double loading':isLoadingSave}"
          style="width: 100%; height: 80%; display: flex"
        >
          <div class="left" style="width: 50%; height: 100%">

            <div class="description_field" style="width: 100%">
              <label style="font-size: 16px; align-self: left; color: #323232">{{  $t('board.headers.description') }}</label>
                <div class="ui input" style="width: 100%">
                  <textarea
                    style="border: 1px solid rgba(34,36,38,.15); resize:none; width: 100%"
                   maxlength="70" :placeholder="$t('board.announcement_description')" rows="4" v-model="descriptionOfBulletin"/>
                </div>
            </div>

          </div>
          <div class="right" style="width: 50%; height: 100%; display: flex; align-items: center">

            <div
              class="right-content"
              style="height: 50%; width: 100%; padding-left: 40px"
              :style="isNewBulletin ? 'margin-top: -35px' : 'margin-top: -205px'">

              <div class="publicationDate">
                <div
                  class="date"
                  style="width: 55%; display: flex; align-items: center">
                  <label style="font-size: 16px; color: #323232; margin-right: 10px">{{  $t('board.headers.publication') }}</label>
                  <Datepicker
                    style="
                    --vdp-bg-color: #f2f2f2; --vdp-hover-bg-color: #88c6df;
                    --vdp-selected-bg-color: #436f98; text-align: center; max-width: 110px"
                    inputFormat='dd/MM/yyyy'
                    :lowerLimit="nowDate"
                    :locale="getLanguage()"
                    :typeable="false"
                    v-model="form.activatedAt"
                  />
                </div>
                <div class="hour" style="width: 45%; display: flex; justify-content: space-between; align-items: center">
                  <label style="font-size: 16px; color: #323232; padding-left: 10px; margin-right: 5px">{{  $t('board.time') }}</label>
                  <div class="mb-2" style="display: flex; flex-direction: row;
                   justify-content: flex-end !important; margin-top: 10px">
                    <SuiDropdown
                      style="min-width: 20px !important; max-width: 20px !important"
                      selection
                      v-model="form.activationHour"
                      placeholder="00"
                      :options="hours"
                    />
                    <SuiDropdown
                      style="min-width: 20px !important; max-width: 20px !important"
                      selection
                      v-model="form.activationHourPeriod"
                      placeholder="AM"
                      :options="['AM', 'PM']"
                    />
                  </div>
                </div>
              </div>

              <div class="expirationDate">
                <div class="date" style="width: 55%; display: flex; align-items: center">
                  <label style="font-size: 16px; color: #323232; margin-right: 17px">{{  $t('board.expiration') }}</label>
                  <Datepicker
                    style="
                    --vdp-bg-color: #f2f2f2; --vdp-hover-bg-color: #88c6df;
                    --vdp-selected-bg-color: #436f98; text-align: center; max-width: 110px"
                    inputFormat='dd/MM/yyyy'
                    :lowerLimit="nowDate"
                    :locale="getLanguage()"
                    :typeable="false"
                    v-model="form.expiresAt"
                  />
                </div>
                <div class="hour" style="width: 45%; display: flex; justify-content: space-between; align-items: center">
                  <label style="font-size: 16px; color: #323232; padding-left: 10px; margin-right: 5px">{{  $t('board.time') }}</label>
                  <div class="mb-2" style="display: flex; flex-direction: row;  justify-content: flex-end !important">
                    <SuiDropdown
                      style="min-width: 20px !important; max-width: 20px !important"
                      selection
                      v-model="form.expiresHour"
                      placeholder="00"
                      :options="hours"
                    />
                    <SuiDropdown
                      style="min-width: 20px !important; max-width: 20px !important"
                      selection
                      v-model="form.expiresHourPeriod"
                      placeholder="AM"
                      :options="['AM', 'PM']"
                    />
                  </div>
                </div>
              </div>

              <div
                class="isImportant_and_isActive"
                style="width: 100%; display:flex; align-items: center"
              >
                <div style="
                  display: flex; flex-direction: column; justify-content: center;
                  width: 50%; height: 100%; justify-content: space-between; padding-top: 10px;
                  align-items: center; padding-left: -35px"
                >
                  <label
                    style="margin-bottom: 10px;color: #323232;">
                  {{  $t('board.headers.active') }}
                  </label>
                  <div
                  class="ui toggle checkbox"
                  style="transform: scale(1.3)"
                  >
                    <input type="checkbox" :v-model="form.isActive" :checked="form.isActive" @change="changeActivation()">
                    <label style="height: 0px; width:0px"></label>
                  </div>
                  <span style="color: #323232;">{{ form.isActive ?  $t('yes')  :  $t('no') }}</span>
                </div>

              </div>

            </div>

          </div>

        </form>

        <div class="btns" :class="{'disabled':isLoadingSave}">
          <Button v-if="!isNewBulletin"
            :appearance="classBtnObject"
            class="delete"
            :text="$t('board.delete')"
            color="third-color"
            @clicked="deleteAnnouncement"
            :class="{'disabled':isLoadingSave}"
          />
          <Button v-if="!isNewBulletin"
            :appearance="classBtnObject"
            class="save"
            :text="$t('board.save')"
            color="primary"
            @clicked="saveChanges"
            :class="{'disabled':isLoadingSave}"
          />
          <Button v-else
            class="d-inline"
            :appearance="classBtnObject"
            :text="$t('board.create')"
            color="primary"
            @clicked="createAnnouncement"
            :class="{'disabled':isLoadingSave}"
          />
        </div>
      </SuiModalDescription>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
// SuiDropdown
import { SuiModal, SuiModalContent, SuiModalDescription, SuiDropdown } from 'vue-fomantic-ui'
import Button from '@/components/form/Button'
import {
  createAnnouncement,
  deleteAnnouncement as deleteAnn,
  updateAnnouncement
} from '@/api/importantAnnouncements'
import Datepicker from 'vue3-datepicker'
import { mapActions, mapGetters } from 'vuex'
import { es, en } from 'date-fns/locale'
import i18n from '@/plugins/i18n'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators/dist/raw.esm'

export default {
  props: {
    show: {
      type: Boolean,
      required: false,
      default: true
    },
    edit_data: {
      type: Object,
      required: false
    },
    isFullOfImportant: {
      type: Boolean,
      required: true
    }
  },
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    Button,
    SuiDropdown,
    Datepicker
    // SuiDropdown

  },
  emits: ['close'],
  watch: {
    edit_data: {
      handler (newVal) {
        if (newVal) {
          console.log('JAJAJAJA', newVal)
        }
      },
      immediate: true // Ejecuta inmediatamente al inicio para manejar el valor inicial
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      form: {
        description: '',
        createdAt: '',
        creationHour: null,
        isActive: false,
        expiresAt: null,
        expiresHour: null,
        expiresHourPeriod: null,
        activatedAt: null,
        activationHour: null,
        activationHourPeriod: null
      },
      isLoadingSave: false,
      hours: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      nowDate: new Date()
    }
  },

  validations () {
    return {
      form: {
        description: {
          required: helpers.withMessage(this.$t('errors.board.must_have_title'), required)
        },
        expiresAt: {
          required: helpers.withMessage(this.$t('errors.board.expirationDate'), required)
        },
        expiresHour: {
          required: helpers.withMessage(this.$t('errors.board.expirationTime'), required)
        },
        expiresHourPeriod: {
          required: helpers.withMessage(this.$t('errors.board.chooseAMPM'), required)
        }
      }
    }
  },

  updated () {
    this.clearForm()
    if (!this.isNewBulletin) {
      // console.log('Incoming values of bulletin already created --->', this.edit_data.values)
      this.form.description = this.edit_data.values.Description
      // setting Dates

      // Creation
      // const creationDate = new Date(this.edit_data.values.createdAt)
      // const creationDate = new Date(this.edit_data.values.createdAt.replace('Z', ''))
      // let creationDay = creationDate.getDate()
      // creationDay = creationDay < 10 ? `0${creationDay}` : creationDay
      // let creationMonth = creationDate.getMonth() + 1
      // creationMonth = creationMonth < 10 ? `0${creationMonth}` : creationMonth
      // this.form.createdAt = `${creationDay}/${creationMonth}/${creationDate.getFullYear()}`
      // let creationHour = creationDate.getHours()
      // const creationPeriod = creationHour > 12 ? 'PM' : 'AM'
      // creationHour = creationHour > 12 ? creationHour - 12 : creationHour
      // creationHour = creationHour < 10 ? `0${creationHour}` : `${creationHour}`
      // let creationMinutes = creationDate.getMinutes()
      // creationMinutes = creationMinutes < 10 ? `0${creationMinutes}` : `${creationMinutes}`
      // this.form.creationHour = `${creationHour}:${creationMinutes} ${creationPeriod}`

      // Activation
      if (this.edit_data.values.activatedAt) {
        // const activationDate = new Date(this.edit_data.values.activatedAt)
        const activationDate = new Date(this.edit_data.values.activatedAt.replace('Z', ''))
        // --------------------------------------------------------------------
        // ESTO DEBE QUITARSE EN EL SIGUIENTE AJUSTE DE HORAS Y FECHAS
        // activationDate.setHours(activationDate.getHours() - 1)
        // --------------------------------------------------------------------
        this.form.activatedAt = activationDate
        let activationHour = activationDate.getHours()
        this.form.activationHourPeriod = activationHour >= 12 ? 'PM' : 'AM'
        activationHour = activationHour > 12 ? activationHour - 12 : (activationHour === 0 ? 12 : activationHour)
        activationHour = activationHour < 10 ? `0${activationHour}` : `${activationHour}`
        this.form.activationHour = `${activationHour}`
      }

      // Expiration
      if (this.edit_data.values.expiresAt) {
        // const expirationDate = new Date(this.edit_data.values.expiresAt)
        const expirationDate = new Date(this.edit_data.values.expiresAt.replace('Z', ''))
        // --------------------------------------------------------------------
        // ESTO DEBE QUITARSE EN EL SIGUIENTE AJUSTE DE HORAS Y FECHAS
        // expirationDate.setHours(expirationDate.getHours() - 1)
        // --------------------------------------------------------------------
        this.form.expiresAt = expirationDate
        let expirationHour = expirationDate.getHours()
        this.form.expiresHourPeriod = expirationHour >= 12 ? 'PM' : 'AM'
        expirationHour = expirationHour > 12 ? expirationHour - 12 : (expirationHour === 0 ? 12 : expirationHour)
        expirationHour = expirationHour < 10 ? `0${expirationHour}` : `${expirationHour}`
        this.form.expiresHour = `${expirationHour}`
      }

      this.form.isActive = this.edit_data.values.active
    } else {
      this.form.activatedAt = this.nowDate
      let nowHour = this.nowDate.getHours()
      nowHour = nowHour > 12 ? nowHour - 12 : (nowHour === 0 ? 12 : nowHour)
      nowHour = nowHour < 10 ? `0${nowHour}` : `${nowHour}`
      this.form.activationHour = `${nowHour}`
      this.form.activationHourPeriod = this.nowDate.getHours() >= 12 ? 'PM' : 'AM'
    }

    console.log('JNJCNJDNCJD', this.isNewBulletin)
  },
  computed: {

    ...mapGetters('importantAnnouncements', [
      'allAnnouncements'
    ]),

    classBtnObject () {
      return {
        height: '29px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        padding: '7px 2.4rem !important'
      }
    },

    es () {
      return es
    },
    en () {
      return en
    },

    expiresAt () {
      return `${this.form.expiresHour}:00:00 ${this.form.expiresHourPeriod}`
    },

    residential () {
      return this.$store.getters['residential/activeResidential']
    },
    dialog () {
      console.log('HYYHHY', this.edit_data)
      return this.show
    },
    isNewBulletin () {
      console.log('dmcjiodvnju', this.edit_data, Object.keys(this.edit_data).length)
      return Object.keys(this.edit_data).length === 0
    },

    descriptionOfBulletin: {
      get () {
        return this.form.description
      },
      set (newVal) {
        this.form.description = newVal
      }
    }
  },
  methods: {
    ...mapActions('loading', ['setLoading']),

    ...mapActions('importantAnnouncements', ['addNewAnnouncement', 'delete_Announcement', 'update_Announcement']),

    getLanguage () {
      if (i18n.global.locale === 'en') {
        return en
      } else {
        return es
      }
    },
    classObjectIsImportant () {
      return {
        importantThumbtack: this.form.isImportant,
        nonImportantThumbtack: !this.form.isImportant
      }
    },

    changeActivation () {
      this.form.isActive = !this.form.isActive
    },

    classObjectFiles () {
      return {
        width: this.isNewBulletin ? '100%' : '60%'
      }
    },

    close () {
      this.$emit('close')
      this.clearForm()
      this.v$.$reset()
    },

    clearForm () {
      this.form = {
        description: '',
        isActive: false,
        expiresAt: null,
        expiresHour: null,
        expiresHourPeriod: '',
        activatedAt: null,
        activationHour: null,
        activationHourPeriod: null
      }
    },

    async createAnnouncement () {
      this.v$.$validate()
      const formValid = !(this.v$.$error)

      if (formValid) {
        // this.isLoadingSave = true
        this.setLoading(true)

        // const data = new FormData()

        // data.append('residentialId', this.residential.residentialId)

        // data.append('description', this.form.description)

        // data.append('expiresAt', this.getCompleteDate(this.form.expiresHour, this.form.expiresHourPeriod, this.form.expiresAt))

        // data.append('status', this.form.isActive ? 'ACTIVE' : 'INACTIVE')

        // data.append('activatedAt', this.getCompleteDate(this.form.activationHour, this.form.activationHourPeriod, this.form.activatedAt))

        const data = {
          residentialId: this.residential.residentialId,
          description: this.form.description,
          expiresAt: this.getCompleteDate(this.form.expiresHour, this.form.expiresHourPeriod, this.form.expiresAt),
          status: this.form.isActive ? 'ACTIVE' : 'INACTIVE',
          activatedAt: this.getCompleteDate(this.form.activationHour, this.form.activationHourPeriod, this.form.activatedAt)
        }

        createAnnouncement(data).then((r) => {
          const bulletin = r.data
          // console.log('created bulletin response from server ---->', bulletin)
          this.addNewAnnouncement({
            id: bulletin.id,
            Description: bulletin.description,
            createdAt: bulletin.createdAt,
            activatedAt: bulletin.activatedAt,
            expiresAt: bulletin.expiresAt,
            active: bulletin.status === 'ACTIVE'
          })
          // console.log(r)
          // this.isLoadingSave = false
          this.close()
          this.$swal(this.$t('board.createdSuccess'), '', 'success')
        }).catch((e) => {
          // this.isLoadingSave = false
          this.$swal(e.response.data.message, '', 'error')
        }).finally(() => {
          this.setLoading(false)
        })
      }
    },

    getCompleteDate (hourStr, period, date) {
      console.log(hourStr, period, date)
      const hourInt = parseInt(hourStr)
      let hourFormatted
      if (period === 'AM') {
        hourFormatted = hourInt === 12 ? 0 : hourInt
      } else {
        hourFormatted = hourInt === 12 ? 12 : 12 + hourInt
      }
      hourFormatted = hourFormatted < 10 ? `0${hourFormatted}` : `${hourFormatted}`
      const dayFormatted = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
      // next change for hour change (UTC refactoring)
      const completeDateExpiration = `${date.getFullYear()}-${date.getMonth() + 1}-${dayFormatted} ${hourFormatted}:00:00`
      // hot fix (production preventive crash)
      // const completeDateExpiration = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${hourFormatted}:00:00`
      return completeDateExpiration
    },

    async saveChanges () {
      // this.isLoadingSave = true
      this.v$.$validate()
      const formValid = !(this.v$.$error)
      // console.log('SAVING CHANGES OF EXISTING BULLETIN')

      if (formValid) {
        this.setLoading(true)

        // const data = new FormData()

        // data.append('description', this.form.description)

        // data.append('expiresAt', this.getCompleteDate(this.form.expiresHour, this.form.expiresHourPeriod, this.form.expiresAt))

        // data.append('activatedAt', this.getCompleteDate(this.form.activationHour, this.form.activationHourPeriod, this.form.activatedAt))

        // // Status
        // data.append('status', this.form.isActive ? 'ACTIVE' : 'INACTIVE')

        const data = {
          description: this.form.description,
          expiresAt: this.getCompleteDate(this.form.expiresHour, this.form.expiresHourPeriod, this.form.expiresAt),
          status: this.form.isActive ? 'ACTIVE' : 'INACTIVE',
          activatedAt: this.getCompleteDate(this.form.activationHour, this.form.activationHourPeriod, this.form.activatedAt)
        }

        console.log('DATAAA', this.getCompleteDate(this.form.expiresHour, this.form.expiresHourPeriod, this.form.expiresAt))
        updateAnnouncement(this.edit_data.id, data).then((r) => {
          const bulletin = r.data
          // console.log('bulletin updated response from server --->', bulletin)
          this.update_Announcement({
            id: bulletin.id,
            Description: bulletin.description,
            activatedAt: bulletin.activatedAt,
            expiresAt: bulletin.expiresAt,
            active: bulletin.status === 'ACTIVE'
          })
          this.close()
          this.$swal(this.$t('board.savedSuccess'), '', 'success')
        }).catch(() => {
          // this.isLoadingSave = false
          this.$swal(this.$t('errors.board.updatingError'), '', 'error')
        }).finally(() => {
          this.setLoading(false)
        })
      }
    },

    async deleteAnnouncement () {
      this.$swal({
        title: this.$t('board.wantToDeleteAnnouncement'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        confirmButtonColor: '#dc3545',
        cancelButtonText: this.$t('no'),
        cancelButtonColor: '#2186b0'
      }).then((result) => {
        if (result.isConfirmed) {
          // this.isLoadingSave = true
          this.setLoading(true)
          deleteAnn(this.edit_data.id).then(r => {
            this.delete_Announcement(this.edit_data)
            this.close()
            this.$swal(this.$t('board.deletedSuccess'), '', 'success')
          }).catch(() => {
            // this.isLoadingSave = false
            this.$swal(this.$t('errors.board.deletingError'), '', 'error')
          }).finally(() => {
            this.setLoading(false)
          })
        }
      })
    }

  }
}
</script>

<style scoped>
  h1 {
      color: #2c3e50 !important;
      font-family: inherit !important;
      }
  .btns {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  textarea:focus {
    border-color: #85b7d9 !important;
  }
  textarea::placeholder {
    color: rgba(34,36,38,.15)
  }
  textarea:focus::placeholder {
    color: rgb(151, 144, 144);
  }

  .title_field, .coverPhoto_field, .date_field{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .description_field{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .description_existing_bulletin {
    margin-top: 30px
  }

    .importantThumbtack, .nonImportantThumbtack {
      color: #808080;
      font-size: 20px;
    }

    .importantThumbtack {
      color: rgb(235, 36, 36);
      /* transform: rotate(-45deg); */
      transform: rotate(0deg);
      transition: ease .3s
    }

    /* .importantThumbtack:hover {
      color: #808080;
      transform: rotate(-45deg)
    } */

    .nonImportantThumbtack {
      color: #808080;
      transform: rotate(-45deg);
      transition: ease .3s
    }

    /* .nonImportantThumbtack:hover {
      color: rgb(235, 36, 36);
      transform: rotate(0);
    } */
    .delete, .save {
      /* height: 40px !important;*/
      text-transform: none !important;
      letter-spacing: normal !important;
      border-radius: 5px !important;
      transition: background-color .5s ease !important;
      border: none !important;
      width: 10rem !important;
      font-family: inherit;
    }
  .delete {
    margin-right: 10px !important;
  }

  .save {
    margin-left: 10px !important;
  }

  .creationDate, .publicationDate, .expirationDate {
    width: 100%;
    display: flex;
    align-items: center
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
}
</style>
