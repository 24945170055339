<template>
  <SuiModal v-model="dialog" class="add_members_modal">
    <SuiModalContent class="add_members_content">
      <a>
        <i
          @click="close()"
          style="text-decoration:none !important; cursor: pointer;font-size: 25px;
          position: sticky; left: 105%; top: 10px; margin-right: -5px; margin-top: -5px;"
          class="times icon general-blue-color">
        </i>
      </a>
      <SuiModalDescription style="width: 100%; padding-right:15px; padding-left:15px; display: flex; flex-direction: column;">
        <div class="align-items-center div-form">
            <h1>{{truncateText(editConversation.subject, maxMsgSubjectLengthEditConversationModal)}}</h1>
            <div class="two_side_container">
                <div class="add_members_container">
                    <h3>{{$t('messages.addMembers')}}</h3>
                    <div class="residents_to_add_search_bar">
                      <multiselect
                        ref="multiselect"
                        @select="onSelect"
                        multiple
                        searchable
                        v-model="membersToAdd"
                        :options="residentialMembersOptions"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :placeholder="$t('messages.choose_residents')"
                        track-by="description"
                        selectLabel=""
                        selectedLabel=""
                        deselectLabel=""
                        :custom-label="memberLabel"
                        hideSelected
                        :max-height="200"
                        :showNoResults="false"
                        :noResultsText="$t('messages.allInChat')"
                        >
                        <template v-slot:option="{option}">
                          <div class="content member_info">
                            <img
                              class="floated mini ui image left"
                              :src="getAvatar(option.avatar)"
                              style="height: 35px; width: 35px; border-radius: 17px; object-fit: cover;"
                            >
                            <div
                              class="header"
                              style="font-size: 15px"
                            >
                              {{ option.fullname }}
                            </div>
                            <div
                              class="meta"
                            >
                              {{ option.apartment }}
                            </div>
                          </div>
                        </template>

                        <template v-slot:noOptions>
                            <div>
                                {{$t('messages.allInChat')}}
                            </div>
                        </template>
                      </multiselect>
                    </div>
                    <Button
                      :class="{'disabled': !isSomeMemberSelectedToAdd}"
                      color="primary"
                      :text="$t('messages.addMembers')"
                      class="d-inline"
                      style="align-self: center"
                      :appearance="{ height: '28px', marginTop: '5px' }"
                      @clicked="addMembers" />
                </div>
            </div>
        </div>
      </SuiModalDescription>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
import {
  SuiModal,
  SuiModalContent,
  SuiModalDescription
} from 'vue-fomantic-ui'
import {
  mapActions,
  mapGetters
} from 'vuex'
import { es } from 'date-fns/locale'
import { useVuelidate } from '@vuelidate/core'
import helpers from '@/utils/helpers'
import Multiselect from 'vue-multiselect'
import Button from '@/components/form/Button'
import { RolesEnum } from '@/utils/roles.enum.js'

export default {
  props: {
    show: {
      type: Boolean,
      required: false,
      default: true
    },
    editConversation: {
      type: Object,
      required: false
    }
  },
  mixins: [{
    methods: {
      truncateText: helpers.truncateText,
      getAvatar: helpers.getAvatar
    }
  }],
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    Multiselect,
    Button
  },
  emits: ['close'],
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      conversationMembersIds: [],
      membersToAdd: [],
      residentsToDelete: [],
      maxMsgSubjectLengthEditConversationModal: 50
    }
  },
  computed: {
    ...mapGetters('messaging', ['conversationActive']),
    ...mapGetters('residential', ['activeResidential']),
    // ...mapGetters('user', ['residentialMembersDictionary']),
    classBtnObject () {
      return {
        height: '29px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        padding: '7px 2.4rem !important'
      }
    },

    es () {
      return es
    },

    dialog () {
      return this.show
    },
    residentsFromStore () {
      return this.$store.getters['user/residents']
    },
    mergeResidentialMembers () {
      // const residentialAdminsDict = this.residentialMembersDictionary[RolesEnum.ADMINISTRATOR]
      // console.log('residentialAdminsDict (modalll)-->', this.residentialAdminsDict)
      const residentialMembersIdSet = new Set(
        [
          ...Object.keys(this.residentialAdminsDict || {}),
          ...Object.keys(this.residentsDict || {})
        ]
      )
      return residentialMembersIdSet
    },

    residentialMembersOptions () {
      // console.log('this.conversationMembersIds from residentialMembersOptions-->', this.conversationMembersIds)
      return [...this.mergeResidentialMembers]
        .filter(memberId => {
          // console.log('memberId to filter-->', memberId)
          const notAConversationMember = !this.conversationMembersIds.includes(+memberId)
          // && memberId !== `${this.adminUser.id}`
          // console.log('should be added to options members to add? -->', notAConversationMember)
          return notAConversationMember
        })
        .map(memberId => {
          let memberInfo
          let apartment
          // console.log('residentialMember id -->', memberId)
          if (memberId in this.residentialAdminsDict) {
            memberInfo = this.residentialAdminsDict[memberId]
            apartment = 'ADMIN'
            // console.log('is admin')
          } else {
            memberInfo = this.residentsDict[memberId]
            apartment = `${memberInfo.apartment}`
            // console.log('is resident')
          }
          return {
            id: memberId,
            fullname: `${memberInfo.firstName} ${memberInfo.lastName}`,
            description: `${memberInfo.firstName} ${memberInfo.lastName}, ${apartment} (${memberInfo.email}).`,
            email: memberInfo.email,
            avatar: this.getAvatar(memberInfo.avatar),
            apartment: apartment
          }
        })
        .sort((a, b) => a.fullname.localeCompare(b.fullname))
    },
    residentsDict () {
      return this.$store.getters['user/residentsDictionary']
    },
    residentialAdminsDict () {
      return this.residentialMembersDictionary[RolesEnum.ADMINISTRATOR]
    },
    residentialMembersDictionary () { // <- podría ser ideal que se trabaje residents + admin + ... con este objeto
      return this.$store.getters['user/residentialMembersDictionary']
    },
    adminUser () {
      return this.$store.getters['user/user']
    },
    conversationMembers () {
      return this.conversationMembersIds
        .map(userId => this.formatUserToShow(userId))
        .sort((a, b) => {
          if (a.apartment === 'ADMIN') return -1
          if (b.apartment === 'ADMIN') return 1

          // Separate letters and numbers
          const regex = /^([a-z]*)(\d*)$/i
          const matchA = a.apartment.match(regex)
          const matchB = b.apartment.match(regex)

          // Compare letters first
          if (matchA[1] < matchB[1]) return -1
          if (matchA[1] > matchB[1]) return 1

          // If letters are equal, compare numbers
          // Here, we're considering that if there's no number part, it should come last
          const numA = matchA[2] === '' ? Infinity : parseInt(matchA[2], 10)
          const numB = matchB[2] === '' ? Infinity : parseInt(matchB[2], 10)

          return numA - numB
        })
    },
    isSomeResidentSelectedToRemove () {
      return this.residentsToDelete.some(x => x)
    },
    isSomeMemberSelectedToAdd () {
      return this.membersToAdd.length > 0
    }
  },
  mounted () {
    this.membersToAdd = []
    this.overrideStylesVueMultiSelect()
    // console.log(this.adminUser)
    // this.loadAdminsInfo()
  },
  updated () {
    this.membersToAdd = []
    this.overrideStylesVueMultiSelect()
    // this.loadAdminsInfo()
  },
  methods: {
    ...mapActions('loading', ['setLoading']),

    loadAdminsInfo () {
      const residentiaMembersQueryData = {
        residentialId: this.activeResidential.residentialId,
        membersRoles: [RolesEnum.ADMINISTRATOR]
      }
      this.setLoading(true)
      this.$store.dispatch('user/loadResidentialMembers', residentiaMembersQueryData)
      // console.log('admin info when modal mounted', this.residentialMembers)
      // console.log('residentialDict info when modal mounted', this.residentsDict)
      this.setLoading(false)
    },

    onSelect () {
      // Using $nextTick to make sure the DOM has updated before we try to scroll
      this.$nextTick(() => {
        const multiselect = this.$refs.multiselect.$el
        const tagsWrapper = multiselect.querySelector('.multiselect__tags')
        tagsWrapper.scrollTop = tagsWrapper.scrollHeight
      })
    },

    overrideStylesVueMultiSelect () {
      document.querySelectorAll('.multiselect__tags').forEach(el => {
        el.style.maxHeight = '120px'
        el.style.overflowY = 'auto'
      })
      document.querySelectorAll('.multiselect__content-wrapper').forEach(el => {
        el.style.maxHeight = '170px'
        el.style.overflowY = 'auto'
      })
    },

    close () {
      this.$emit('close')
    },

    clearForm () {
    },

    formatUserToShow (userId) {
      let user = this.residentsDict[userId] || this.adminUser
      if (this.residentsDict[userId].email === this.adminUser.email) { // if admin is also a resident
        user = this.adminUser
      }
      const { id, firstName, lastName, avatar, apartment } = user
      const fullName = `${firstName} ${lastName}`
      const specs = `${userId.building} - ${userId.apartment}`
      return {
        id: this.residentsDict[userId] ? userId : id,
        description: `${fullName}, ${specs}`,
        fullname: `${fullName}`,
        avatar: avatar,
        apartment: user === this.adminUser ? 'ADMIN' : apartment
      }
    },

    memberLabel ({ id, fullname, apartment }) {
      return `${fullname}, ${apartment}`
    },
    addMembers () {
      this.setLoading(true)
      // console.log('adding members -----> ', this.membersToAdd)
      const membersToAddWithInfo = this.membersToAdd.map(x => {
        let memberInfo
        let specs
        if (x.id in this.residentialAdminsDict) {
          memberInfo = this.residentialAdminsDict[x.id]
          specs = 'ADMIN'
        } else {
          memberInfo = this.residentsDict[x.id]
          specs = `${memberInfo.building} - ${memberInfo.apartment}`
        }
        return {
          id: +x.id,
          firstName: memberInfo.firstName,
          lastName: memberInfo.lastName,
          avatar: memberInfo.avatar,
          specs: specs
        }
      })
      this.$store.dispatch('messaging/editConversationMembers', {
        conversationId: this.conversationActive.uid,
        data: {
          membersToAdd: membersToAddWithInfo
        }
      }).then(() => {
        this.setLoading(false)
        this.$swal(this.$t('messages.addedSuccessfully'), '', 'success')
        this.close()
      })
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
  h1 {
      color: #2c3e50 !important;
      font-family: inherit !important;
      }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
}

.two_side_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.current_members_container {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.add_members_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

/* .current_members_list {
  max-height: 315px;
  overflow-y: auto;
} */

.residents_to_add_search_bar {
  height: 330px;
}

.member_card {
  margin-bottom: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.member_info {
  padding: 6px;
  width: 90%;
}

.member_info_checkbox_delete {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add_members_content {
  height: 540px; display: flex; flex-direction:column
}

  /* ----------------------------------------- multiselect style --------------------------------------- */
  .multiselect__tags {
    max-height: 290px !important;
    overflow-y: auto !important
  }
  .multiselect__content-wrapper {
    max-height: 270px !important
  }
  .multiselect__tag-icon::after {
    content: "×";
    color: #8C98A1 !important;
    font-size: 14px;
  }

  .multiselect__option--highlight {
    background: #DAE1E7 !important;
    outline: none;
    color: #606F7B !important;
  }

  .multiselect__option--highlight::after {
    content: attr(data-select);
    background: #DAE1E7 !important;
    color: #606F7B !important;
  }

  @media only screen and (max-width: 670px) {

    .add_members_content {
      height: 600px;
    }
  }
</style>
