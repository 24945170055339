import apiApp from '@/api/config/apiApp'

function getImportantAnnouncements (residentialId) {
  return apiApp.get(`/featured-ad/residential/${residentialId}`)
}

function createAnnouncement (params) {
  return apiApp.post('/featured-ad', params)
}

function updateAnnouncement (id, params) {
  console.log(id, params)
  return apiApp.patch(`/featured-ad/${id}`, params)
}

function changeActivationStatus (id) {
  // console.log('changing activation from api', params)
  return apiApp.patch(`/featured-ad/${id}/toggle`)
}

function deleteAnnouncement (id) {
  return apiApp.delete(`/featured-ad/${id}`)
}

export {
  getImportantAnnouncements,
  createAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
  changeActivationStatus
}
